import initialState from './initialState';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, GET_LOGGED_IN_USER_INFO, RECEIVE_USER } from '../actions/actionTypes';

let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
let token = localStorage.getItem('token');
var foundState = user && token ? { loggedIn: false, loggingIn: false, user, token } : initialState.authentication;

export default function authenticationReducer(state = foundState, action) {

	switch (action.type) {
		case LOGIN_REQUEST:
			return {
				loggingIn: true,
				loggedIn: false,
				user: action.user,
				token: action.user.token,
				roles: action.user.roles,
				authorizations: action.user.authorizations
			};
		case LOGIN_SUCCESS:
			return {
				loggingIn: false,
				loggedIn: true,
				user: action.user,
				token: action.user.token,
				roles: action.user.roles,
				authorizations: action.user.authorizations
			};
		case LOGIN_FAILURE:
			return { loggingIn: false, loggedIn: false };
		case LOGOUT:
			return {
				loggingIn: false, loggedIn: false,
				user: {},
				roles: [],
				authorizations: [],
			};
		case GET_LOGGED_IN_USER_INFO:
			return {
				...state,
				loggingIn: false,
				loggedIn: true,
				user: action.user,
				roles: action.user.roles,
				authorizations: action.user.authorizations,
			};
		case RECEIVE_USER:
			return {
				...state,
				user: action.user,
			};
		default:
			return state
	}
}