import initialState from './initialState';
import { FETCH_ORDERS, RECEIVE_ORDERS } from '../actions/actionTypes';

export default function ordersReducer(state = initialState.orders, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_ORDERS:
			return { ...state, fetching: true };
		case RECEIVE_ORDERS:
			newState.items = action.orders.items;
			newState.pagination = action.orders.pagination;
			newState.pagination.pageSizeOptions = state.pagination.pageSizeOptions;
			newState.search = action.orders.search;
			newState.userId = action.orders.userId;
			newState.orderStatus = action.orders.orderStatus;
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}