import initialState from './initialState';
import { ALERT_SUCCESS, ALERT_ERROR, ALERT_CLEAR } from '../actions/actionTypes';

export default function alertReducer(state = initialState.alert, action) {
	switch (action.type) {

		case ALERT_SUCCESS:
			return action.alert;
		case ALERT_ERROR:
			return action.alert;
		case ALERT_CLEAR:
			return {};

		default:
			return state
	}
}