import React, { Component, Suspense, lazy } from 'react';

import LoadingPage from './components/layout/LoadingPage';
import LoadingComponent from './components/LoadingComponent';

import { ConfigProvider } from 'antd';
import nl_BE from 'antd/lib/locale-provider/nl_BE';
import fr_BE from 'antd/lib/locale-provider/fr_BE';
import en_US from 'antd/lib/locale-provider/en_US';
import moment from 'moment';
import 'moment/locale/nl-be';
import 'moment/locale/fr';

import { withTranslation } from 'react-i18next';

const LoadableRoot = lazy(() => import('./routes/Root'));

class App extends Component {

	constructor(props) {
		super(props);

		this.state = { isLoading: true }
		this.setLoading = this.setLoading.bind(this);
	}

	componentDidMount() {
		this.setState({ isLoading: false });
	}

	setLoading(loading) {
		this.setState({ isLoading: loading });
	}

	render() {
		return (
			this.state.isLoading ?
				<LoadingPage />
				:
				<Suspense fallback={<LoadingComponent />}>
					<LocaleSwitcher>
						<LoadableRoot setLoading={this.setLoading} />
					</LocaleSwitcher>
				</Suspense>
		);
	}
}

export default App;


/** Locales/Languages config */
const LocaleSwitcher = withTranslation()(class LocaleProviderWithState extends React.Component {
	state = {
		antd_locale: en_US,
		language: "en"
	}

	componentDidMount() {
		this.checkAndChangeLanguage();
	}

	componentDidUpdate() {
		this.checkAndChangeLanguage();
	}

	checkAndChangeLanguage() {

		const { i18n } = this.props;

		let locale = i18n.language;
		// eslint-disable-next-line no-mixed-operators
		/*
		var language = navigator.languages && navigator.languages[0] ||
			navigator.language ||
			navigator.userLanguage;

		*/
		if (!locale || locale === this.state.language) return;
		switch (locale) {
			case 'nl':
				this.setState({ antd_locale: nl_BE, language: locale });
				moment.locale('nl-be');
				break;
			case 'fr':
				this.setState({ antd_locale: fr_BE, language: locale });
				moment.locale('fr');
				break;
			default:
			case 'en':
				this.setState({ antd_locale: en_US, language: locale });
				moment.locale('en');
				break;
		}
	}

	render() {
		return (
			<ConfigProvider locale={this.state.antd_locale}>
				{this.props.children}
			</ConfigProvider>

		)
	}
});