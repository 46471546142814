import { combineReducers } from 'redux';
import products from './productsReducer';
import alert from './alertReducer';
import authentication from './authenticationReducer';
import articles from './articlesReducer';
import article from './articleReducer';
import articleGroups from './articleGroupsReducer';
import cart from './cartReducer';
import orders from './ordersReducer';
import order from './orderReducer';
import orderDetailsFromMultiPress from './orderDetailsReducer';
import users from './usersReducer';
import user from './userReducer';
import countries from './countriesReducer';
import shippingOptions from './shippingOptionsReducer';
import addresses from './addressesReducer';
import shopInfo from './shopInfoReducer';
import roles from './rolesReducer';
import customers from './customersReducer';
import departments from './departmentsReducer';
import groupings from './groupingsReducer';
import approvals from './approvalsReducer';
import approval from './approvalReducer';
import tagTypes from './tagTypesReducer';
import updateBlogs from './updateBlogsReducer';
import updateBlog from './updateBlogReducer'

const rootReducer = combineReducers({
	products,
	alert,
	authentication,
	articles,
	article,
	cart,
	articleGroups,
	orders,
	order,
	orderDetailsFromMultiPress,
	users,
	user,
	countries,
	shippingOptions,
	addresses,
	shopInfo,
	roles,
	customers,
	departments,
	groupings,
	approvals,
	approval,
	tagTypes,
	updateBlogs,
	updateBlog
});

export default rootReducer;
