import initialState from './initialState';
import {
    FETCH_ORDER_DETAILS_FROM_MULTIPRESS,
    RECEIVE_ORDER_DETAILS_FROM_MULTIPRESS,
    RESET_ORDER_DETAILS_FROM_MULTIPRESS
} from '../actions/actionTypes';

export default function orderDetailsReducer(state = initialState.orderDetailsFromMultiPress, action) {
    let newState = {};
    switch (action.type) {
        case FETCH_ORDER_DETAILS_FROM_MULTIPRESS:
            return { ...state, fetching: true };
        case RECEIVE_ORDER_DETAILS_FROM_MULTIPRESS:
            newState = action.orderDetailsFromMultiPress;
            newState.fetching = false;

            return newState;
        case RESET_ORDER_DETAILS_FROM_MULTIPRESS:
            return { fetching: false };
        default:
            return state;
    }
}
