import initialState from './initialState';
import { FETCH_ROLES, RECEIVE_ROLES } from '../actions/actionTypes';

export default function rolesReducer(state = initialState.roles, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_ROLES:
			return { ...state, fetching: true };
		case RECEIVE_ROLES:
			newState.items = action.roles.items;
			newState.pagination = action.roles.pagination;
			newState.pagination.pageSizeOptions = state.pagination.pageSizeOptions;
			newState.search = action.roles.search;
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}