import initialState from './initialState';
import { FETCH_ARTICLES, RECEIVE_ARTICLES } from '../actions/actionTypes';

export default function articlesReducer(state = initialState.articles, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_ARTICLES:
			return { ...state, fetching: true };
		case RECEIVE_ARTICLES:
			newState.items = action.articles.items;
			newState.pagination = action.articles.pagination;
			newState.pagination.pageSizeOptions = state.pagination.pageSizeOptions;
			newState.search = action.articles.search;
			newState.tagValueIds = action.articles.tagValueIds;
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}