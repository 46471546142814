import initialState from './initialState';
import { FETCH_ARTICLE, RECEIVE_ARTICLE } from '../actions/actionTypes';

export default function articleReducer(state = initialState.article, action) {
    let newState = {};
    switch (action.type) {
        case FETCH_ARTICLE:
            return { ...state, fetching: true };
        case RECEIVE_ARTICLE:
            newState = action.article;
            newState.fetching = false;
            return newState;
        default:
            return state;
    }
}