import initialState from './initialState';
import { FETCH_SHIPPINGOPTIONS, RECEIVE_SHIPPINGOPTIONS } from '../actions/actionTypes';

export default function shippingOptionsReducer(state = initialState.shippingOptions, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_SHIPPINGOPTIONS:
			return { ...state, fetching: true };
		case RECEIVE_SHIPPINGOPTIONS:
			newState.items = action.shippingOptions.items;
			newState.pagination = action.shippingOptions.pagination;
			newState.pagination.pageSizeOptions = state.pagination.pageSizeOptions;
			newState.search = action.shippingOptions.search;
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}