import initialState from './initialState';
import { FETCH_BLOGS, RECEIVE_BLOGS } from '../actions/actionTypes';

export default function updateBlogsReducer(state = initialState.updateBlogs, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_BLOGS:
			return { ...state, fetching: true };
		case RECEIVE_BLOGS:
			newState.items = action.updateBlogs.items;
			newState.pagination = action.updateBlogs.pagination;
			newState.search = action.updateBlogs.search;
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}