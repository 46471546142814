import React from 'react';
import LoadingPage from './layout/LoadingPage';

export default function LoadingComponent(props) {
    if (props.error) {
      return <div>Error while loading component. Please try refreshing the page if this problem persists.</div>;
    } else if (props.timedOut) {
      return <div>Request timed out. Please try refreshing the page if this problem persists.</div>;
    } else if (props.pastDelay) {
      return <LoadingPage />;
    } else {
      return null;
    }
  }