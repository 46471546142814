import initialState from './initialState';
import { FETCH_ORDER, RECEIVE_ORDER, PLACE_ORDER, RECEIVE_PLACED_ORDER } from '../actions/actionTypes';

export default function orderReducer(state = initialState.order, action) {
    let newState = {};
    switch (action.type) {
        case FETCH_ORDER:
            return { ...state, fetching: true };
        case RECEIVE_ORDER:
            newState = action.order;
            newState.fetching = false;
            return newState;
        case PLACE_ORDER:
            return { ...state, fetching: true };
        case RECEIVE_PLACED_ORDER:
            newState = action.order;
            newState.fetching = false;
            return newState;
        default:
            return state;
    }
}