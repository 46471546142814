import initialState from './initialState';
import { GET_LOGGED_IN_USER_INFO, FETCH_USER, RECEIVE_USER, CREATE_USER, RECEIVE_CREATED_USER, UPDATE_USER, UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS, } from '../actions/actionTypes';

export default function usersReducer(state = initialState.user, action) {
    let newState = {};
    switch (action.type) {
        case FETCH_USER:
            return { ...state, fetching: true };
        case RECEIVE_USER:
            newState = action.user;
            newState.fetching = false;
            return newState;
        case CREATE_USER:
            return { ...state, fetching: true };
        case UPDATE_USER:
            return { ...state, fetching: true };
        case RECEIVE_CREATED_USER:
            newState = action.user;
            newState.fetching = false;
            return newState;
        case UPDATE_PASSWORD:
            return { ...state, fetching: true };
        case UPDATE_PASSWORD_SUCCESS:
            return { ...state, fetching: false };
        case GET_LOGGED_IN_USER_INFO:
            newState = action.user;
            newState.fetching = false
            return newState;
        default:
            return state;
    }
}