import initialState from './initialState';
import { FETCH_COUNTRIES, RECEIVE_COUNTRIES } from '../actions/actionTypes';

export default function countriesReducer(state = initialState.countries, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_COUNTRIES:
			return { ...state, fetching: true };
		case RECEIVE_COUNTRIES:
			newState.items = action.countries.items;
			newState.pagination = action.countries.pagination;
			newState.pagination.pageSizeOptions = state.pagination.pageSizeOptions;
			newState.search = action.countries.search;
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}