import initialState from './initialState';
import { FETCH_ADDRESSES, RECEIVE_ADDRESSES } from '../actions/actionTypes';

export default function addressesReducer(state = initialState.addresses, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_ADDRESSES:
			return { ...state, fetching: true };
		case RECEIVE_ADDRESSES:
			newState.items = action.addresses.items;
			newState.pagination = action.addresses.pagination;
			newState.pagination.pageSizeOptions = state.pagination.pageSizeOptions;
			newState.search = action.addresses.search;
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}