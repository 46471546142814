import initialState from './initialState';
import { FETCH_INFO, RECEIVE_INFO } from '../actions/actionTypes';

export default function shopInfoReducer(state = initialState.cart, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_INFO:
			return { ...state, fetching: true };
		case RECEIVE_INFO:
			newState = action.shopInfo || {};
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}