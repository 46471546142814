import initialState from './initialState';
import { FETCH_DEPARTMENTS, RECEIVE_DEPARTMENTS } from '../actions/actionTypes';

export default function departmentsReducer(state = initialState.departments, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_DEPARTMENTS:
			return { ...state, fetching: true };
		case RECEIVE_DEPARTMENTS:
			newState.items = action.departments.items;
			newState.pagination = action.departments.pagination;
			newState.pagination.pageSizeOptions = state.pagination.pageSizeOptions;
			newState.search = action.departments.search;
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}