import initialState from './initialState';
import { FETCH_CART, RECEIVE_CART, EMPTY_CART, ADD_ARTICLE_TO_CART, REMOVE_ARTICLE_FROM_CART, UPDATE_CART } from '../actions/actionTypes';

export default function cartReducer(state = initialState.cart, action) {
	let newState = {};
	switch (action.type) {

		case FETCH_CART:
			return { ...state, fetching: true };
		case RECEIVE_CART:
			newState.items = action.cart.items;
			newState.approvalAllowed = action.cart.approvalAllowed;
			newState.approvalRequired = action.cart.approvalRequired;
			newState.fetching = false;
			return newState;

		case ADD_ARTICLE_TO_CART:
			var index = state.items.findIndex(x => (x.id === action.data.id));
			if (index !== -1) {
				newState = {
					...state, items: state.items.map((item, currentIndex) => index === currentIndex ? action.data : item)
				}
			} else if (action.data.id === null) {
				var d = new Date();
				var n = d.getTime();
				newState.items = [...state.items, {
					id: n,
					articleId: action.data.article?.id,
					article: action.data.article,
					amount: action.data.amount
				}];
			} else {
				newState.items = [...state.items, action.data];
			}

			let sortItems = newState.items.sort(x => x.articleId === 0 ? 1 : -1);
			newState.items = sortItems;
			return newState
		case REMOVE_ARTICLE_FROM_CART:
			newState = {
				...state, items: state.items.filter((item) => item.id !== action.data.id)
			}
			return newState;
		case EMPTY_CART:
			return { ...state, items: [] };
		case UPDATE_CART:
			var index = state.items.findIndex(x => x.id === action.data.id);
			if (index !== -1) {
				newState = {
					...state, items: state.items.map((item, currentIndex) => index === currentIndex ? action.data : item)
				}
			}
			return newState;
		default:
			return state;
	}
}