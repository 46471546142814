import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';


i18n
	// load translation using xhr -> see /public/locales
	// learn more: https://github.com/i18next/i18next-xhr-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({

		//detection: langDetectOptions,

		defaultNS: "common",
		ns: ["common", "authentication", "navigation", "admin", "user", "countries", "article", "cart", "home", "about", "order", "approval", "news"],

		fallbackLng: 'en',
		whitelist: [
			'en', 'nl', 'fr', 'de'
		],
		debug: false,
		transSupportBasicHtmlNodes: true,
		transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'b', 'ul', 'ol', 'li', 'img'],

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
			format: function (value, format, lng) {
				if (format === 'en-handle-an') return (!lng || lng === 'en') ? getVariationOfAOrAn(value, false) + " " + value : '';
				if (format === 'en-handle-an-capitalized') return (!lng || lng === 'en') ? getVariationOfAOrAn(value, true) + " " + value : '';
				if (format === 'fr-handle-de') return (!lng || lng === 'fr') ? getVariationOfDeOrD(value, false) + " " + value : '';
				if (format === 'fr-handle-de-capitalized') return (!lng || lng === 'fr') ? getVariationOfDeOrD(value, true) + " " + value : '';
				if (value instanceof Date) return moment(value).format(format);
				return value;
			}
		},
		saveMissing: false,
		backend: {
			// the returned path will interpolate lng, ns if provided like giving a static path
			loadPath: '/locales/{{lng}}/{{ns}}.json',
			// path to post missing resources
			// addPath: '/locales/missing/{{lng}}/{{ns}}.json',
			queryStringParams: { v: process.env.REACT_APP_MYCONNECT_VERSION }
		}
	})
	//.on('languageChanged', function(lng) {
	//  moment.locale(lng);
	//})
	;


export default i18n;

const getVariationOfAOrAn = function (value, capitalize) {
	const letters = ['a', 'e', 'i', 'o', 'u', 'h'];
	let firstLetter = value.substring(0, 1);
	let correctWordForm = '';
	if (letters.find(function (l) {
		return firstLetter === l;
	})) {
		correctWordForm = capitalize ? 'An' : 'an';
	} else {
		correctWordForm = capitalize ? 'A' : 'a';
	}

	return correctWordForm;
}

const getVariationOfDeOrD = function (value, capitalize) {
	const letters = ['a', 'e', 'i', 'o', 'u', 'h'];
	let firstLetter = value.substring(0, 1);
	let correctWordForm = '';
	if (letters.find(function (l) {
		return firstLetter === l;
	})) {
		correctWordForm = capitalize ? 'De' : 'de';
	} else {
		correctWordForm = capitalize ? 'D\'' : 'd\'';
	}

	return correctWordForm;
}