
const INDEX_PAGE_SIZE_DEFAULT = 12;
const INDEX_PAGE_SIZE_OPTIONS = ["12", "24", "48", "96", "192"];

const defaultPaginationOptions = {
	currentPage: 1, //page
	pageCount: 0, //pageTotal
	pageSize: INDEX_PAGE_SIZE_DEFAULT, //amount of objects found in this page
	pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS, //options for page size selectable by user for this entity
	rowCount: 1, //total, amount of objects total over all pages for this search
	firstRowOnPage: 1, //first id found on page
	lastRowOnPage: 1, //last id found on page
}

const objectListDefaultState = {
	items: [],
	pagination: defaultPaginationOptions,
	search: null
};

const articlesListDefaultState = { ...objectListDefaultState, tagValueIds: [] }

export default {
	articles: articlesListDefaultState,
	article: {},
	articleGroups: objectListDefaultState,
	products: [],
	alert: {},
	authentication: {},
	cart: {},
	transaction: {},
	orders: objectListDefaultState,
	order: {},
	orderDetailsFromMultiPress: {},
	users: objectListDefaultState,
	user: {},
	countries: objectListDefaultState,
	shippingOptions: objectListDefaultState,
	addresses: objectListDefaultState,
	shopInfo: {},
	roles: objectListDefaultState,
	customers: objectListDefaultState,
	departments: objectListDefaultState,
	groupings: objectListDefaultState,
	approvals: objectListDefaultState,
	approval: {},
	tagTypes: objectListDefaultState,
	updateBlogs: objectListDefaultState,
	updateBlog: {}
};
