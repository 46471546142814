import initialState from './initialState';
import { FETCH_BLOG, RECEIVE_BLOG } from '../actions/actionTypes';

export default function updateBlogReducer(state = initialState.updateBlog, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_BLOG:
			return { ...state, fetching: true };
		case RECEIVE_BLOG:
			newState = action.updateBlog;
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}