import './polyfills';
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import './styles/index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import store from './store/store';
import './i18n';
import history from './history';

Sentry.init({
	dsn: "https://6f04521980a54d5daaf06e9a3a911c33@o946505.ingest.sentry.io/5918878",
	environment: process.env.SENTRY_ENVIRONMENT,
	tracesSampleRate: 0.2,
	integrations: [
		new Integrations.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
		}),
	],
});

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
