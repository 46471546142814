import initialState from './initialState';
import { FETCH_APPROVAL, RECEIVE_APPROVAL, RESPOND_TO_APPROVAL, RECEIVE_RESPONDED_TO_APPROVAL } from '../actions/actionTypes';

export default function approvalReducer(state = initialState.approval, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_APPROVAL:
			return { ...state, fetching: true };
		case RECEIVE_APPROVAL:
			newState = action.approval;
			newState.fetching = false;
			return newState;
		case RESPOND_TO_APPROVAL:
			return { ...state, fetching: true };
		case RECEIVE_RESPONDED_TO_APPROVAL:
			newState = action.approval;
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}