
const loggerMiddleware = store => next => action => {

	/**
	 * TODO: Logging...
	 */

	next(action);
}

export default loggerMiddleware;