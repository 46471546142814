import initialState from './initialState';
import { FETCH_USERS, RECEIVE_USERS, RECEIVE_USER } from '../actions/actionTypes';

export default function usersReducer(state = initialState.users, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_USERS:
			return { ...state, fetching: true };
		case RECEIVE_USERS:
			newState.items = action.users.items;
			newState.pagination = action.users.pagination;
			newState.pagination.pageSizeOptions = state.pagination.pageSizeOptions;
			newState.search = action.users.search;
			newState.fetching = false;
			return newState;
		case RECEIVE_USER:
			newState = { ...state };
			return newState;
		default:
			return state;
	}
}