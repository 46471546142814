import React from 'react';
import { Spin } from 'antd';

function LoadingPage({ style, text }) {
	const allStyles = {
		textAlign: "center",
		padding: "30px 50px",
		margin: "20px 0", ...style
	}
	return (
		<div className="loading-page" style={allStyles}>
			{text ?
				<div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
					<div style={{ marginRight: '10px' }}>{text}</div>
					<Spin />
				</div>
				:
				<Spin />
			}
		</div>
	);
}

export default LoadingPage;