export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';

export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const RECEIVE_ARTICLES = 'RECEIVE_ARTICLES';
export const ARTICLES_ERROR = 'ARTICLES_ERROR';
export const FETCH_ARTICLE = 'FETCH_ARTICLE';
export const RECEIVE_ARTICLE = 'RECEIVE_ARTICLE';
export const ARTICLE_ERROR = 'ARTICLE_ERROR';

export const FETCH_ARTICLE_STOCK = 'FETCH_ARTICLE_STOCK';
export const RECEIVE_ARTICLE_STOCK = 'RECEIVE_ARTICLE_STOCK';

export const FETCH_ARTICLEGROUPS = 'FETCH_ARTICLEGROUPS';
export const RECEIVE_ARTICLEGROUPS = 'RECEIVE_ARTICLEGROUPS';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const ORDERS_ERROR = 'ORDERS_ERROR';
export const FETCH_ORDER = 'FETCH_ORDER';
export const RECEIVE_ORDER = 'RECEIVE_ORDER';
export const PLACE_ORDER = 'PLACE_ORDER';
export const RECEIVE_PLACED_ORDER = 'RECEIVE_PLACED_ORDER';
export const ORDER_ERROR = 'ORDER_ERROR';

export const FETCH_ORDER_DETAILS_FROM_MULTIPRESS = 'FETCH_ORDER_DETAILS_FROM_MULTIPRESS';
export const RECEIVE_ORDER_DETAILS_FROM_MULTIPRESS = 'RECEIVE_ORDER_DETAILS_FROM_MULTIPRESS';
export const RESET_ORDER_DETAILS_FROM_MULTIPRESS = 'RESET_ORDER_DETAILS_FROM_MULTIPRESS';

export const FETCH_USERS = 'FETCH_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const FETCH_USER = 'FETCH_USER';
export const USERS_ERROR = 'USERS_ERROR';

export const FETCH_BLOGS = 'FETCH_BLOGS';
export const RECEIVE_BLOGS = 'RECEIVE_BLOGS';
export const FETCH_BLOG = 'FETCH_BLOG';
export const BLOGS_ERROR = 'BLOGS_ERROR';
export const RECEIVE_BLOG = 'RECEIVE_BLOG';

export const RECEIVE_USER = 'RECEIVE_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const RECEIVE_CREATED_USER = 'RECEIVE_CREATED_USER';
export const USER_ERROR = 'USER_ERROR';

export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_CLEAR = 'ALERT_CLEAR';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';

export const GET_LOGGED_IN_USER_INFO = "GET_LOGGED_IN_USER_INFO";

export const FETCH_CART = 'FETCH_CART';
export const RECEIVE_CART = 'RECEIVE_CART';
export const ADD_ARTICLE_TO_CART = 'ADD_ARTICLE_TO_CART';
export const ADD_ARTICLE_TO_CART_FAILURE = 'ADD_ARTICLE_TO_CART_FAILURE';
export const REMOVE_ARTICLE_FROM_CART = 'REMOVE_ARTICLE_FROM_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const CART_ERROR = 'CART_ERROR';
export const UPDATE_CART = "UPDATE_CART";

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES';
export const COUNTRIES_ERROR = 'COUNTRIES_ERROR';

export const FETCH_SHIPPINGOPTIONS = 'FETCH_SHIPPINGOPTIONS';
export const RECEIVE_SHIPPINGOPTIONS = 'RECEIVE_SHIPPINGOPTIONS';
export const SHIPPINGOPTIONS_ERROR = 'SHIPPINGOPTIONS_ERROR';

export const FETCH_ADDRESSES = 'FETCH_ADDRESSES';
export const RECEIVE_ADDRESSES = 'RECEIVE_ADDRESSES';
export const ADDRESSES_ERROR = 'ADDRESSES_ERROR';

export const FETCH_INFO = 'FETCH_INFO';
export const RECEIVE_INFO = 'RECEIVE_INFO';
export const INFO_ERROR = 'INFO_ERROR';

export const FETCH_ROLES = 'FETCH_ROLES';
export const RECEIVE_ROLES = 'RECEIVE_ROLES';
export const ROLES_ERROR = 'ROLES_ERROR';

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS';
export const CUSTOMERS_ERROR = 'CUSTOMERS_ERROR';

export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

export const UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';

export const FETCH_DEPARTMENTS = 'FETCH_DEPARTMENTS';
export const RECEIVE_DEPARTMENTS = 'RECEIVE_DEPARTMENTS';
export const DEPARTMENTS_ERROR = 'DEPARTMENTS_ERROR';

export const FETCH_GROUPINGS = 'FETCH_GROUPINGS';
export const RECEIVE_GROUPINGS = 'RECEIVE_GROUPINGS';
export const GROUPINGS_ERROR = 'GROUPINGS_ERROR';

export const FETCH_APPROVALS = 'FETCH_APPROVALS';
export const RECEIVE_APPROVALS = 'RECEIVE_APPROVALS';
export const APPROVALS_ERROR = 'APPROVALS_ERROR';
export const FETCH_APPROVAL = 'FETCH_APPROVAL';
export const RECEIVE_APPROVAL = 'RECEIVE_APPROVAL';
export const RESPOND_TO_APPROVAL = 'RESPOND_TO_APPROVAL';
export const RECEIVE_RESPONDED_TO_APPROVAL = 'RECEIVE_RESPONDED_TO_APPROVAL';
export const APPROVAL_ERROR = 'APPROVAL_ERROR';

export const FETCH_TAGTYPES = 'FETCH_TAGTYPES';
export const RECEIVE_TAGTYPES = 'RECEIVE_TAGTYPES';
export const TAGTYPES_ERROR = 'TAGTYPES_ERROR';
