import initialState from './initialState';
import { FETCH_PRODUCTS, RECEIVE_PRODUCTS } from '../actions/actionTypes';

export default function productsReducer(state = initialState.products, action) {
    let newState;
    switch (action.type) {
        case FETCH_PRODUCTS:
            return action;
        case RECEIVE_PRODUCTS:
            newState = action.products;
            return newState;
        default:
            return state;
    }
}