import initialState from './initialState';
import { FETCH_GROUPINGS, RECEIVE_GROUPINGS } from '../actions/actionTypes';

export default function groupingsReducer(state = initialState.groupings, action) {
	let newState = {};
	switch (action.type) {
		case FETCH_GROUPINGS:
			return { ...state, fetching: true };
		case RECEIVE_GROUPINGS:
			newState.items = action.groupings.items;
			newState.pagination = action.groupings.pagination;
			newState.pagination.pageSizeOptions = state.pagination.pageSizeOptions;
			newState.search = action.groupings.search;
			newState.fetching = false;
			return newState;
		default:
			return state;
	}
}