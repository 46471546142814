import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/rootReducer';
import thunk from 'redux-thunk';
import loggerMiddleware from '../middlewares/loggerMiddleware';

function configureStore() {

	const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const store = createStore(
		rootReducer,
		composeEnhancer(applyMiddleware(thunk, loggerMiddleware))
	);

	return store;
}

const store = configureStore();

export default store;